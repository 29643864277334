import React, { useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"

import Img from "gatsby-image"
import Layout from "@components/layout"
import { Wrap, Products, Item } from "@components/products"
import Button from "@components/button"

import opengraphImage from "../images/opengraph.jpg"

const filtersSet = [
  {label: "All", match: ""},
  {label: "Jordan", match: "jordan"},
  {label: "Nike", match: "nike"},
  {label: "New Balance", match: "new balance"},
  {label: "Dunk", match: "dunk"},
]

const Filters = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 1rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  position: sticky;
  top: var(--wrap-padding-y);
  z-index: 1;
  margin-right: -1px;
`

function Filter({ children, ...props }) {
  return (
    <Button
      {...props}
      styles={`
        background-color: var(--ground-color);
        background-image: linear-gradient(0deg, var(--ground-color), var(--ground-color));

        &:hover {
          background-image: linear-gradient(0deg, hsla(0, 0%, var(--border-color-l), 0.33), hsla(0, 0%, var(--border-color-l), 0.33)),
                            linear-gradient(0deg, var(--ground-color), var(--ground-color));
        }

        & + & {
          border-left: 0;
        }

        &.selected {
          color: var(--text-high);
          background-color: var(--border-color);
          background-image: none;
          z-index: 2;
        }
      `}
    >
      {children}
    </Button>
  )
}

export default function CollectionPage({ data: { allMdx, allFile, mainPlaceholder } }) {
  const { edges: products } = allMdx
  const { nodes: images } = allFile
  const [filter, setFilter] = useState("")

  return (
    <Layout>
      <Helmet>
        <title>DUNK.SUPPLY</title>
        <meta property="og:title" content="DUNK.SUPPLY" />
        <meta property="og:image" content={opengraphImage} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Wrap>
        <Products>
          <Filters>
            {filtersSet.map((element, index) => (
              <Filter
                onClick={() => setFilter(element.match)}
                className={filter === element.match ? "selected" : null}
                key={index}
              >
                {element.label}
              </Filter>
            ))}
          </Filters>
          {products
            .filter(({ node: product }) =>
              product.frontmatter.brand.toLowerCase() === filter ||
              product.frontmatter.model.toLowerCase().includes(filter)
            )
            .map(({ node: product }) => {
            const image = images.find(image => image.relativePath.includes(product.fields.relativeSlug)).childImageSharp.fluid

            return (
              <Item key={product.id}>
                <div>
                  <figure>
                    <Img
                      fluid={image}
                      durationFadeIn={333}
                      alt=""
                    />
                  </figure>
                  <h3>
                    {product.frontmatter.collab ? `${product.frontmatter.collab} x ` : null}
                    {product.frontmatter.brand} {product.frontmatter.model}
                  </h3>
                  <span />
                </div>
              </Item>
            )
          })}
        </Products>
      </Wrap>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allMdx(
      sort: {
        order: [ASC, ASC, ASC],
        fields: [frontmatter___brand, frontmatter___collab, frontmatter___model]
      }
      filter: {
        fileAbsolutePath: { regex: "\\/collection/" }
        frontmatter: { status: { ne: "inactive" } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            brand
            collab
            model
          }
          fields {
            relativeSlug
            slug
          }
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/main.png$/" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
      }
    }
  }
`
