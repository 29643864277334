import React from "react"
import { css } from "@emotion/react"

const baseStyle = css`
  font-family: var(--monospace);
  font-size: 0.825rem;
  color: var(--text-normal);
  text-transform: uppercase;
  border: 1px solid var(--border-color);
  background-color: transparent;
  padding: 0.75rem 1rem;
  justify-self: start;
  transition-property: box-shadow, color, background-color;
  transition-timing-function: ease;
  transition-duration: 0.125s;
  z-index: 1;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.0625rem var(--ground-color),
                0 0 0 0.25rem hsla(0, 0%, var(--border-color-l), 0.33);
  }

  &:hover {
    color: var(--text-high);
    background-color: hsla(0, 0%, var(--border-color-l), 0.33);

    span {
      transform: scale(1.5) translateX(-1px);
    }
  }
`

const Button = ({ styles = ``, children, ...props }) => (
  <button
    {...props}
    css={css`
      ${baseStyle}
      ${styles}
    `}
  >
    {children}
  </button>
)

export default Button
